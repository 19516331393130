.btn_settings {
  border-radius: 100px;
  font-size: 1.8rem;
  font-weight: 700;
  // For temporary
  min-height: 50px;
  transition: 0.3s;
  border: 1px solid #00000000;
  white-space: nowrap;
}

.btnShadow {
  box-shadow: 0px 17px 30px 0px rgba(0, 0, 0, 0.1);
}

.btnSm {
  padding: 1.1rem 3.1rem;
}

.btnMd {
  padding: 1.1rem 4.6rem;
}

.btnLg {
  padding: 1.1rem 8.1rem;
}

// Theme
.btnBlue {
  background: #2196f3;
  color: #fff;
  i {
    color: #fff;
    opacity: 100 !important;
  }
}

.btnGreen {
  background: #4caf50;
  color: #fff;
}

.btnGreenDisable {
  background: #4caf50;
  opacity: 0.6;
  color: #ffffff;
}

.btnBlueDisable {
  background: hsla(207, 90%, 54%, 0.6);
  color: #fff;
}

.btnGray {
  background: gray;
  color: #fff;
  opacity: 0.3;
}

.btnWhite {
  font-weight: 400;
  background: #fff;
  color: #565656;
  border: 1px solid #aeaeae;
}

// Hovers
.btnWhite:hover {
  border: 1px solid #ffc300;
  color: #ffc300;
}
.btnBlue:hover {
  background: #2080cd;
  color: #fff !important;
}

// Mutaion
.btnBlueMutaiton {
  @extend .btnBlue;
  font-weight: 400;
}

.submitError {
  border: 1px solid #ff8282;
}
@media (max-width: 50em) {
  .btn_settings {
    font-size: 1.4rem;
    width: 100%;
  }
}
