.wrapper {
  border-radius: 25px;
  border: 1px solid #aeaeae;
  width: 100%;
  overflow: hidden;
  margin-top: 2rem;
  position: absolute;
  z-index: 999;
  background: #fff;
}

.item {
  font-size: 1.8rem;
  font-weight: 400;
  color: #565656;
  padding: 1.15rem 4rem;
  transition: 0.2s;
  cursor: pointer;
}

.item:hover {
  background: #ffc300;
  color: #ffffff;
}
