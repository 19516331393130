.wrapper {
  // padding-bottom: 7.5rem;

  overflow: hidden;
}
.steps {
  // max-width: 1071px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
}

.rope {
  position: absolute;
  justify-self: flex-start;
  top: 125px;
  z-index: 0;
  width: 50%;
  // padding: 10rem;
}

.arrowOneYellow {
  transform: translate(-6.9px, 265px);
}

.arrowTwoYellow {
  transform: translate(-6.9px, 285px);
}
// .quiz_bg {
//   background: '#E4F6FC';
//   display: 'flex';
//   align-items: 'center';
//   width: '98.7vw';
//   justify-content: 'center';
//   margin-top: '10rem';
// }
@media (max-width: 50em) {
}
