#svg-container-1 {
  position: absolute;
  top: 60px;
  left: -120px;
  z-index: -1;

  // First Line
  .firstline {
    top: 0px;

    width: 120px;

    * {
      transition: 1s;
    }
    .arrow1 {
      transform: translate(-7px, 3%);
      animation: arrow1 3s forwards;
    }
    .arrow2 {
      transform: translate(-7px, 0%);
      animation: arrow2 3s forwards;
    }
    .leftCorner {
      transform: translate(0px, calc(100% - 57px));
    }
  }
  .bottomHr {
    position: absolute;
    bottom: 0;
    left: 115px;
  }

  .rightCorner {
    position: absolute;
    bottom: -69px;
    left: 547px;
    width: 100%;
  }

  .step1Rope {
    left: 554px;
  }

  .step1Arrow {
    transform: translate(40px, 81%);
  }

  @keyframes arrow1 {
    0% {
      transform: translate(-7px, 3%);
    }
    100% {
      transform: translate(-7px, 50%);
    }
  }
  @keyframes arrow2 {
    0% {
      transform: translate(-7px, 0%);
    }
    100% {
      transform: translate(-7px, 47%);
    }
  }
  @media (max-width: 65em) {
    display: none;
  }
  // @media (min-width: 1600px) {
  //   top: 100px;
  // }
  // @media (min-width: 2500px) {
  //   top: 400px;
  // }
}

#svg-container-2 {
  position: absolute;
  top: 31px;
  left: 668.9px;
  z-index: -1;

  // First Line
  .firstline {
    top: 0px;
    width: 120px;

    * {
      transition: 1s;
    }
    .arrow1 {
      transform: translate(-7px, 3%);
      animation: arrow1 3s forwards;
    }
    .arrow2 {
      transform: translate(-7px, 0%);
      animation: arrow2 3s forwards;
    }
    .rightBottomCorner {
      transform: translate(-57px, calc(100% - 66px));
    }
  }
  .bottomHr {
    position: absolute;
    bottom: -0.5px;
    left: -865px;
  }

  .bottomHrShort {
    position: absolute;
    bottom: -0.4px;
    left: -384px;
  }

  .rightCorner {
    position: absolute;
    bottom: -69px;
    left: 980px;
  }
  .bottomCornerEnd {
    position: absolute;
    bottom: -40.4px;
    left: -437px;
    #blueArrow {
      transform: translate(-8px, 28px);
    }
  }
  @keyframes arrow1 {
    0% {
      transform: translate(-7px, 3%);
    }
    100% {
      transform: translate(-7px, 50%);
    }
  }
  @keyframes arrow2 {
    0% {
      transform: translate(-7px, 0%);
    }
    100% {
      transform: translate(-7px, 47%);
    }
  }
  @media (max-width: 65em) {
    display: none;
  }
}

#svg-container-3 {
  position: absolute;
  top: 69.89px;
  left: -300px;
  z-index: -1;

  // For step 3
  .leftCornerFirst {
    position: absolute;
    left: 58.7px;
    top: -40px;
  }

  // First Line
  .firstline {
    top: 0px;
    width: 120px;

    * {
      transition: 1s;
    }
    .arrow1 {
      transform: translate(-7px, 3%);
      animation: arrow1 3s forwards;
    }
    .arrow2 {
      transform: translate(-7px, 0%);
      animation: arrow2 3s forwards;
    }
    .leftCorner {
      transform: translate(0px, calc(100% - 57px));
    }
  }
  .bottomHr {
    position: absolute;
    bottom: 0;
    left: 200px;
  }

  .bottomHrShort {
    position: absolute;
    bottom: -0.2px;
    left: 116px;
  }

  .rightCorner {
    position: absolute;
    bottom: -69px;
    left: 478px;
  }

  #pin-head {
    transform: translate(41px, 55px);
  }
  @keyframes arrow1 {
    0% {
      transform: translate(-7px, 3%);
    }
    100% {
      transform: translate(-7px, 50%);
    }
  }
  @keyframes arrow2 {
    0% {
      transform: translate(-7px, 0%);
    }
    100% {
      transform: translate(-7px, 47%);
    }
  }
  @media (max-width: 65em) {
    display: none;
  }
}
