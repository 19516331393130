.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  // gap:22rem;
  gap: 10rem;
  justify-content: center;
  align-items: center;
  background-size: cover;
  min-height: 700px;
  padding: 3rem;
  margin-bottom: 6rem;
}

.hero_container {
  display: grid;
  padding: 0rem;
  // gap: 7rem;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;

  // padding: 0 5rem;
  p:not(.hero_cta) {
    width: 500px;
  }
}

.hero {
  display: grid;
  justify-items: flex-start;
  gap: 1.5rem;
  .title {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 54px;
    color: #34314c;
    // width: 100%;
    width: 650px;
    white-space: pre-wrap;
  }
  .description {
    font-size: 1.8rem;
    color: #565656;
    max-width: 498px;
    white-space: pre-wrap;
  }
}
.logo {
  width: 100%;
  max-width: 370px;
}

@media (max-width: 60em) {
  .wrapper {
    height: unset;
  }
  // Ignore line break
  br {
    display: none;
  }
  .hero_container {
    gap: 1rem;
    padding: 0rem 3rem;
    justify-content: center;
  }
  .hero {
    margin-top: 6rem;
    gap: 1.6rem;
    .title {
      font-size: 3rem;
      white-space: pre-wrap;
      line-height: 42px;
      max-width: none;
    }
    .description {
      font-size: 1.6rem;
      white-space: pre-wrap;
      width: 100%;
      max-width: none;
      line-height: 27px;
      margin-top: -0.8rem;
    }
  }
  .logo {
    grid-row: 2/3;
    max-width: 180px;
    margin-top: 2.4rem;
  }
}
