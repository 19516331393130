@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --black: #34314c;
  --lightBlue: #e4f6fc;
  --blue: #2196f3;
  --red: #ff6969;
  --main: #565656;
  --green: #00b746;
  --gray: #828282;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

html {
  font-size: 62.5%;
  min-height: 101vh;
}

body {
  font-size: 1.6rem;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}

input {
  border: none;
  outline: none;
}

main {
  position: relative;
  display: grid;
}

article {
  background:rgb(221, 242, 247);
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}
// Hide input number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: none;
}

.anim {
  animation: fadeInAnimation 2s forwards;
}

.anim1 {
  animation: fadeInAnimation1 2s forwards;
}

.anim-fadeIn {
  animation: fadeIn 1s forwards;
}

.btn-error {
  border: 1px rgba(255, 0, 0, 0.452) solid;
}

.block-content {
  ul {
    display: grid;
    justify-items: flex-start;
  }
}

/* Logo Slider */

.logo-slider {
  max-width: 1120px;
  background: white;
  -webkit-box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.logo-slider::before,
.logo-slider::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    to(rgba(255, 255, 255, 0))
  );
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: '';
  height: 175px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.logo-slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.logo-slider::before {
  left: 0;
  top: 0;
}

.logo-slider .logo-slide-track {
  -webkit-animation: logo-scroll 60s linear infinite;
  animation: logo-scroll 60s linear infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(250px * 14);
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

.logo-slider .slide {
  height: 250px;
  width: 250px;

  padding: 20px;
}

@-webkit-keyframes logo-scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes logo-scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  100% {
    z-index: -1;
  }
}

@keyframes fadeInAnimation1 {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  100% {
    z-index: -1;
  }
}
