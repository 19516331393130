.stats {
  &-wrapper {
    display: grid;
    position: relative;
    place-items: center;
    width: 1120px;
    margin: 0rem auto 0 auto;
    padding: 0rem 0 5rem 0;
    background: #fff;
    box-shadow: 0px 26px 80px 0px hsla(214, 9%, 64%, 0.1);
  }
  &-recommend {
    margin: 4.3rem 0;
    width: 100%;
  }
  &-title {
    font-size: 3.2rem;
    font-weight: 600;
    color: var(--black);
    margin-top: 3rem;
  }
  // Pin SVG
  &-pin {
    display: grid;
    width: 100%;
    z-index: 999;
    background: center bottom / 100% rgb(221, 242, 247);
  }

  // Dissolve Animation
  &-fade {
    animation-name: dissolve;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  @media (max-width: 70em) {
    &-wrapper {
      margin: 0rem auto 2rem auto;
      height: unset;
      padding: unset;
      padding-bottom: 2.4rem;
    }
    &-title {
      font-size: 2.4rem;
      text-align: center;
      font-weight: 600;
      color: var(--black);
    }
  }
}

.breakdown {
  &-wrapper {
    display: flex;
    margin-top: 3.4rem;
    gap: 8.4rem;
    @media (max-width: 70em) {
      flex-direction: column;
      margin-top: 2rem;
      gap: unset;
    }
  }
  &-fade {
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 100%;
    height: 100%;
  }
}

.circle {
  &-wrapper {
    margin-left: 14rem;
    display: grid;
    place-items: center;
    position: relative;
    canvas {
      pointer-events: none;
    }
  }
  &-savings {
    text-align: center;
    position: absolute;
    &-amount {
      font-size: 4rem;
      font-weight: 700;
      line-height: 20px;
      margin-top: 3rem;
      color: var(--blue);
    }
    &-title {
      font-size: 1.6rem;
      font-weight: 400;
      max-width: 80px;
      text-align: center;
      margin: 2rem auto 0rem auto;
      line-height: 20px;
      color: var(--main);
    }
  }
  @media (max-width: 70em) {
    &-wrapper {
      margin-left: unset;
    }

    &-savings {
      &-amount {
        font-size: 3.4rem;
      }
      &-title {
        font-size: 1.4rem;
      }
    }
  }
}

@media (max-width: 70em) {
  .circle-svg {
    width: 200px;
  }
}

// Slide Show Styles
.slider {
  &-wrapper {
    width: 100%;
  }
}

.svg-rope-pin {
  transform: translate(0px, 30px);
}

.svg-rope-circle {
  transform: translate(0px, -20px);
}

.svg-rope-arrow {
  transform: translate(0px, 80px);
}

// Animations

// Blue Button Animations
#layer2 {
  animation-name: fade2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
}

#layer1 {
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
}

@keyframes dissolve {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 100;
  }
  70% {
    opacity: 100;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade2 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }

  50% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

// Circle svg manipulation
#extend-path-black {
}
